export const enum EDay {
	NUMERIC = 'numeric',
}

export const enum EMonth {
	LONG = 'long',
}

export const enum EYear {
	NUMERIC = 'numeric',
}

export const enum EHour {
	NUMERIC = 'numeric',
}

export const enum EMinute {
	NUMERIC = 'numeric',
}
