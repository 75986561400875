"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.characterOrders = void 0;
/* @generated */
// prettier-ignore
exports.characterOrders = {
    "aa": "left-to-right",
    "aa-DJ": "left-to-right",
    "aa-ER": "left-to-right",
    "ab": "left-to-right",
    "af": "left-to-right",
    "af-NA": "left-to-right",
    "agq": "left-to-right",
    "ak": "left-to-right",
    "am": "left-to-right",
    "an": "left-to-right",
    "ann": "left-to-right",
    "apc": "right-to-left",
    "ar": "right-to-left",
    "ar-AE": "right-to-left",
    "ar-BH": "right-to-left",
    "ar-DJ": "right-to-left",
    "ar-DZ": "right-to-left",
    "ar-EG": "right-to-left",
    "ar-EH": "right-to-left",
    "ar-ER": "right-to-left",
    "ar-IL": "right-to-left",
    "ar-IQ": "right-to-left",
    "ar-JO": "right-to-left",
    "ar-KM": "right-to-left",
    "ar-KW": "right-to-left",
    "ar-LB": "right-to-left",
    "ar-LY": "right-to-left",
    "ar-MA": "right-to-left",
    "ar-MR": "right-to-left",
    "ar-OM": "right-to-left",
    "ar-PS": "right-to-left",
    "ar-QA": "right-to-left",
    "ar-SA": "right-to-left",
    "ar-SD": "right-to-left",
    "ar-SO": "right-to-left",
    "ar-SS": "right-to-left",
    "ar-SY": "right-to-left",
    "ar-TD": "right-to-left",
    "ar-TN": "right-to-left",
    "ar-YE": "right-to-left",
    "arn": "left-to-right",
    "as": "left-to-right",
    "asa": "left-to-right",
    "ast": "left-to-right",
    "az": "left-to-right",
    "az-Arab": "right-to-left",
    "az-Arab-IQ": "right-to-left",
    "az-Arab-TR": "right-to-left",
    "az-Cyrl": "left-to-right",
    "az-Latn": "left-to-right",
    "ba": "left-to-right",
    "bal": "right-to-left",
    "bal-Arab": "right-to-left",
    "bal-Latn": "left-to-right",
    "bas": "left-to-right",
    "be": "left-to-right",
    "be-tarask": "left-to-right",
    "bem": "left-to-right",
    "bew": "left-to-right",
    "bez": "left-to-right",
    "bg": "left-to-right",
    "bgc": "left-to-right",
    "bgn": "right-to-left",
    "bgn-AE": "right-to-left",
    "bgn-AF": "right-to-left",
    "bgn-IR": "right-to-left",
    "bgn-OM": "right-to-left",
    "bho": "left-to-right",
    "blo": "left-to-right",
    "blt": "left-to-right",
    "bm": "left-to-right",
    "bm-Nkoo": "right-to-left",
    "bn": "left-to-right",
    "bn-IN": "left-to-right",
    "bo": "left-to-right",
    "bo-IN": "left-to-right",
    "br": "left-to-right",
    "brx": "left-to-right",
    "bs": "left-to-right",
    "bs-Cyrl": "left-to-right",
    "bs-Latn": "left-to-right",
    "bss": "left-to-right",
    "byn": "left-to-right",
    "ca": "left-to-right",
    "ca-AD": "left-to-right",
    "ca-ES-valencia": "left-to-right",
    "ca-FR": "left-to-right",
    "ca-IT": "left-to-right",
    "cad": "left-to-right",
    "cch": "left-to-right",
    "ccp": "left-to-right",
    "ccp-IN": "left-to-right",
    "ce": "left-to-right",
    "ceb": "left-to-right",
    "cgg": "left-to-right",
    "cho": "left-to-right",
    "chr": "left-to-right",
    "cic": "left-to-right",
    "ckb": "right-to-left",
    "ckb-IR": "right-to-left",
    "co": "left-to-right",
    "cs": "left-to-right",
    "csw": "left-to-right",
    "cu": "left-to-right",
    "cv": "left-to-right",
    "cy": "left-to-right",
    "da": "left-to-right",
    "da-GL": "left-to-right",
    "dav": "left-to-right",
    "de": "left-to-right",
    "de-AT": "left-to-right",
    "de-BE": "left-to-right",
    "de-CH": "left-to-right",
    "de-IT": "left-to-right",
    "de-LI": "left-to-right",
    "de-LU": "left-to-right",
    "dje": "left-to-right",
    "doi": "left-to-right",
    "dsb": "left-to-right",
    "dua": "left-to-right",
    "dv": "right-to-left",
    "dyo": "left-to-right",
    "dz": "left-to-right",
    "ebu": "left-to-right",
    "ee": "left-to-right",
    "ee-TG": "left-to-right",
    "el": "left-to-right",
    "el-CY": "left-to-right",
    "el-polyton": "left-to-right",
    "en": "left-to-right",
    "en-001": "left-to-right",
    "en-150": "left-to-right",
    "en-AE": "left-to-right",
    "en-AG": "left-to-right",
    "en-AI": "left-to-right",
    "en-AS": "left-to-right",
    "en-AT": "left-to-right",
    "en-AU": "left-to-right",
    "en-BB": "left-to-right",
    "en-BE": "left-to-right",
    "en-BI": "left-to-right",
    "en-BM": "left-to-right",
    "en-BS": "left-to-right",
    "en-BW": "left-to-right",
    "en-BZ": "left-to-right",
    "en-CA": "left-to-right",
    "en-CC": "left-to-right",
    "en-CH": "left-to-right",
    "en-CK": "left-to-right",
    "en-CM": "left-to-right",
    "en-CX": "left-to-right",
    "en-CY": "left-to-right",
    "en-DE": "left-to-right",
    "en-DG": "left-to-right",
    "en-DK": "left-to-right",
    "en-DM": "left-to-right",
    "en-Dsrt": "left-to-right",
    "en-ER": "left-to-right",
    "en-FI": "left-to-right",
    "en-FJ": "left-to-right",
    "en-FK": "left-to-right",
    "en-FM": "left-to-right",
    "en-GB": "left-to-right",
    "en-GD": "left-to-right",
    "en-GG": "left-to-right",
    "en-GH": "left-to-right",
    "en-GI": "left-to-right",
    "en-GM": "left-to-right",
    "en-GU": "left-to-right",
    "en-GY": "left-to-right",
    "en-HK": "left-to-right",
    "en-ID": "left-to-right",
    "en-IE": "left-to-right",
    "en-IL": "left-to-right",
    "en-IM": "left-to-right",
    "en-IN": "left-to-right",
    "en-IO": "left-to-right",
    "en-JE": "left-to-right",
    "en-JM": "left-to-right",
    "en-KE": "left-to-right",
    "en-KI": "left-to-right",
    "en-KN": "left-to-right",
    "en-KY": "left-to-right",
    "en-LC": "left-to-right",
    "en-LR": "left-to-right",
    "en-LS": "left-to-right",
    "en-MG": "left-to-right",
    "en-MH": "left-to-right",
    "en-MO": "left-to-right",
    "en-MP": "left-to-right",
    "en-MS": "left-to-right",
    "en-MT": "left-to-right",
    "en-MU": "left-to-right",
    "en-MV": "left-to-right",
    "en-MW": "left-to-right",
    "en-MY": "left-to-right",
    "en-NA": "left-to-right",
    "en-NF": "left-to-right",
    "en-NG": "left-to-right",
    "en-NL": "left-to-right",
    "en-NR": "left-to-right",
    "en-NU": "left-to-right",
    "en-NZ": "left-to-right",
    "en-PG": "left-to-right",
    "en-PH": "left-to-right",
    "en-PK": "left-to-right",
    "en-PN": "left-to-right",
    "en-PR": "left-to-right",
    "en-PW": "left-to-right",
    "en-RW": "left-to-right",
    "en-SB": "left-to-right",
    "en-SC": "left-to-right",
    "en-SD": "left-to-right",
    "en-SE": "left-to-right",
    "en-SG": "left-to-right",
    "en-SH": "left-to-right",
    "en-Shaw": "left-to-right",
    "en-SI": "left-to-right",
    "en-SL": "left-to-right",
    "en-SS": "left-to-right",
    "en-SX": "left-to-right",
    "en-SZ": "left-to-right",
    "en-TC": "left-to-right",
    "en-TK": "left-to-right",
    "en-TO": "left-to-right",
    "en-TT": "left-to-right",
    "en-TV": "left-to-right",
    "en-TZ": "left-to-right",
    "en-UG": "left-to-right",
    "en-UM": "left-to-right",
    "en-VC": "left-to-right",
    "en-VG": "left-to-right",
    "en-VI": "left-to-right",
    "en-VU": "left-to-right",
    "en-WS": "left-to-right",
    "en-ZA": "left-to-right",
    "en-ZM": "left-to-right",
    "en-ZW": "left-to-right",
    "eo": "left-to-right",
    "es": "left-to-right",
    "es-419": "left-to-right",
    "es-AR": "left-to-right",
    "es-BO": "left-to-right",
    "es-BR": "left-to-right",
    "es-BZ": "left-to-right",
    "es-CL": "left-to-right",
    "es-CO": "left-to-right",
    "es-CR": "left-to-right",
    "es-CU": "left-to-right",
    "es-DO": "left-to-right",
    "es-EA": "left-to-right",
    "es-EC": "left-to-right",
    "es-GQ": "left-to-right",
    "es-GT": "left-to-right",
    "es-HN": "left-to-right",
    "es-IC": "left-to-right",
    "es-MX": "left-to-right",
    "es-NI": "left-to-right",
    "es-PA": "left-to-right",
    "es-PE": "left-to-right",
    "es-PH": "left-to-right",
    "es-PR": "left-to-right",
    "es-PY": "left-to-right",
    "es-SV": "left-to-right",
    "es-US": "left-to-right",
    "es-UY": "left-to-right",
    "es-VE": "left-to-right",
    "et": "left-to-right",
    "eu": "left-to-right",
    "ewo": "left-to-right",
    "fa": "right-to-left",
    "fa-AF": "right-to-left",
    "ff": "left-to-right",
    "ff-Adlm": "right-to-left",
    "ff-Adlm-BF": "right-to-left",
    "ff-Adlm-CM": "right-to-left",
    "ff-Adlm-GH": "right-to-left",
    "ff-Adlm-GM": "right-to-left",
    "ff-Adlm-GW": "right-to-left",
    "ff-Adlm-LR": "right-to-left",
    "ff-Adlm-MR": "right-to-left",
    "ff-Adlm-NE": "right-to-left",
    "ff-Adlm-NG": "right-to-left",
    "ff-Adlm-SL": "right-to-left",
    "ff-Adlm-SN": "right-to-left",
    "ff-Latn": "left-to-right",
    "ff-Latn-BF": "left-to-right",
    "ff-Latn-CM": "left-to-right",
    "ff-Latn-GH": "left-to-right",
    "ff-Latn-GM": "left-to-right",
    "ff-Latn-GN": "left-to-right",
    "ff-Latn-GW": "left-to-right",
    "ff-Latn-LR": "left-to-right",
    "ff-Latn-MR": "left-to-right",
    "ff-Latn-NE": "left-to-right",
    "ff-Latn-NG": "left-to-right",
    "ff-Latn-SL": "left-to-right",
    "fi": "left-to-right",
    "fil": "left-to-right",
    "fo": "left-to-right",
    "fo-DK": "left-to-right",
    "fr": "left-to-right",
    "fr-BE": "left-to-right",
    "fr-BF": "left-to-right",
    "fr-BI": "left-to-right",
    "fr-BJ": "left-to-right",
    "fr-BL": "left-to-right",
    "fr-CA": "left-to-right",
    "fr-CD": "left-to-right",
    "fr-CF": "left-to-right",
    "fr-CG": "left-to-right",
    "fr-CH": "left-to-right",
    "fr-CI": "left-to-right",
    "fr-CM": "left-to-right",
    "fr-DJ": "left-to-right",
    "fr-DZ": "left-to-right",
    "fr-GA": "left-to-right",
    "fr-GF": "left-to-right",
    "fr-GN": "left-to-right",
    "fr-GP": "left-to-right",
    "fr-GQ": "left-to-right",
    "fr-HT": "left-to-right",
    "fr-KM": "left-to-right",
    "fr-LU": "left-to-right",
    "fr-MA": "left-to-right",
    "fr-MC": "left-to-right",
    "fr-MF": "left-to-right",
    "fr-MG": "left-to-right",
    "fr-ML": "left-to-right",
    "fr-MQ": "left-to-right",
    "fr-MR": "left-to-right",
    "fr-MU": "left-to-right",
    "fr-NC": "left-to-right",
    "fr-NE": "left-to-right",
    "fr-PF": "left-to-right",
    "fr-PM": "left-to-right",
    "fr-RE": "left-to-right",
    "fr-RW": "left-to-right",
    "fr-SC": "left-to-right",
    "fr-SN": "left-to-right",
    "fr-SY": "left-to-right",
    "fr-TD": "left-to-right",
    "fr-TG": "left-to-right",
    "fr-TN": "left-to-right",
    "fr-VU": "left-to-right",
    "fr-WF": "left-to-right",
    "fr-YT": "left-to-right",
    "frr": "left-to-right",
    "fur": "left-to-right",
    "fy": "left-to-right",
    "ga": "left-to-right",
    "ga-GB": "left-to-right",
    "gaa": "left-to-right",
    "gd": "left-to-right",
    "gez": "left-to-right",
    "gez-ER": "left-to-right",
    "gl": "left-to-right",
    "gn": "left-to-right",
    "gsw": "left-to-right",
    "gsw-FR": "left-to-right",
    "gsw-LI": "left-to-right",
    "gu": "left-to-right",
    "guz": "left-to-right",
    "gv": "left-to-right",
    "ha": "left-to-right",
    "ha-Arab": "right-to-left",
    "ha-Arab-SD": "right-to-left",
    "ha-GH": "left-to-right",
    "ha-NE": "left-to-right",
    "haw": "left-to-right",
    "he": "right-to-left",
    "hi": "left-to-right",
    "hi-Latn": "left-to-right",
    "hnj": "left-to-right",
    "hnj-Hmnp": "left-to-right",
    "hr": "left-to-right",
    "hr-BA": "left-to-right",
    "hsb": "left-to-right",
    "hu": "left-to-right",
    "hy": "left-to-right",
    "ia": "left-to-right",
    "id": "left-to-right",
    "ie": "left-to-right",
    "ig": "left-to-right",
    "ii": "left-to-right",
    "io": "left-to-right",
    "is": "left-to-right",
    "it": "left-to-right",
    "it-CH": "left-to-right",
    "it-SM": "left-to-right",
    "it-VA": "left-to-right",
    "iu": "left-to-right",
    "iu-Latn": "left-to-right",
    "ja": "left-to-right",
    "jbo": "left-to-right",
    "jgo": "left-to-right",
    "jmc": "left-to-right",
    "jv": "left-to-right",
    "ka": "left-to-right",
    "kaa": "left-to-right",
    "kaa-Cyrl": "left-to-right",
    "kaa-Latn": "left-to-right",
    "kab": "left-to-right",
    "kaj": "left-to-right",
    "kam": "left-to-right",
    "kcg": "left-to-right",
    "kde": "left-to-right",
    "kea": "left-to-right",
    "ken": "left-to-right",
    "kgp": "left-to-right",
    "khq": "left-to-right",
    "ki": "left-to-right",
    "kk": "left-to-right",
    "kk-Arab": "right-to-left",
    "kk-Cyrl": "left-to-right",
    "kk-KZ": "left-to-right",
    "kkj": "left-to-right",
    "kl": "left-to-right",
    "kln": "left-to-right",
    "km": "left-to-right",
    "kn": "left-to-right",
    "ko": "left-to-right",
    "ko-CN": "left-to-right",
    "ko-KP": "left-to-right",
    "kok": "left-to-right",
    "kok-Deva": "left-to-right",
    "kok-Latn": "left-to-right",
    "kpe": "left-to-right",
    "kpe-GN": "left-to-right",
    "ks": "right-to-left",
    "ks-Arab": "right-to-left",
    "ks-Deva": "left-to-right",
    "ksb": "left-to-right",
    "ksf": "left-to-right",
    "ksh": "left-to-right",
    "ku": "left-to-right",
    "kw": "left-to-right",
    "kxv": "left-to-right",
    "kxv-Deva": "left-to-right",
    "kxv-Latn": "left-to-right",
    "kxv-Orya": "left-to-right",
    "kxv-Telu": "left-to-right",
    "ky": "left-to-right",
    "la": "left-to-right",
    "lag": "left-to-right",
    "lb": "left-to-right",
    "lg": "left-to-right",
    "lij": "left-to-right",
    "lkt": "left-to-right",
    "lld": "left-to-right",
    "lmo": "left-to-right",
    "ln": "left-to-right",
    "ln-AO": "left-to-right",
    "ln-CF": "left-to-right",
    "ln-CG": "left-to-right",
    "lo": "left-to-right",
    "lrc": "right-to-left",
    "lrc-IQ": "right-to-left",
    "lt": "left-to-right",
    "ltg": "left-to-right",
    "lu": "left-to-right",
    "luo": "left-to-right",
    "luy": "left-to-right",
    "lv": "left-to-right",
    "mai": "left-to-right",
    "mas": "left-to-right",
    "mas-TZ": "left-to-right",
    "mdf": "left-to-right",
    "mer": "left-to-right",
    "mfe": "left-to-right",
    "mg": "left-to-right",
    "mgh": "left-to-right",
    "mgo": "left-to-right",
    "mhn": "left-to-right",
    "mi": "left-to-right",
    "mic": "left-to-right",
    "mk": "left-to-right",
    "ml": "left-to-right",
    "mn": "left-to-right",
    "mn-Mong": "top-to-bottom",
    "mn-Mong-MN": "top-to-bottom",
    "mni": "left-to-right",
    "mni-Beng": "left-to-right",
    "mni-Mtei": "left-to-right",
    "moh": "left-to-right",
    "mr": "left-to-right",
    "ms": "left-to-right",
    "ms-Arab": "right-to-left",
    "ms-Arab-BN": "right-to-left",
    "ms-BN": "left-to-right",
    "ms-ID": "left-to-right",
    "ms-SG": "left-to-right",
    "mt": "left-to-right",
    "mua": "left-to-right",
    "mus": "left-to-right",
    "my": "left-to-right",
    "myv": "left-to-right",
    "mzn": "right-to-left",
    "naq": "left-to-right",
    "nb": "left-to-right",
    "nb-SJ": "left-to-right",
    "nd": "left-to-right",
    "nds": "left-to-right",
    "nds-NL": "left-to-right",
    "ne": "left-to-right",
    "ne-IN": "left-to-right",
    "nl": "left-to-right",
    "nl-AW": "left-to-right",
    "nl-BE": "left-to-right",
    "nl-BQ": "left-to-right",
    "nl-CW": "left-to-right",
    "nl-SR": "left-to-right",
    "nl-SX": "left-to-right",
    "nmg": "left-to-right",
    "nn": "left-to-right",
    "nnh": "left-to-right",
    "no": "left-to-right",
    "nqo": "right-to-left",
    "nr": "left-to-right",
    "nso": "left-to-right",
    "nus": "left-to-right",
    "nv": "left-to-right",
    "ny": "left-to-right",
    "nyn": "left-to-right",
    "oc": "left-to-right",
    "oc-ES": "left-to-right",
    "om": "left-to-right",
    "om-KE": "left-to-right",
    "or": "left-to-right",
    "os": "left-to-right",
    "os-RU": "left-to-right",
    "osa": "left-to-right",
    "pa": "left-to-right",
    "pa-Arab": "right-to-left",
    "pa-Guru": "left-to-right",
    "pap": "left-to-right",
    "pap-AW": "left-to-right",
    "pcm": "left-to-right",
    "pis": "left-to-right",
    "pl": "left-to-right",
    "prg": "left-to-right",
    "ps": "right-to-left",
    "ps-PK": "right-to-left",
    "pt": "left-to-right",
    "pt-AO": "left-to-right",
    "pt-CH": "left-to-right",
    "pt-CV": "left-to-right",
    "pt-GQ": "left-to-right",
    "pt-GW": "left-to-right",
    "pt-LU": "left-to-right",
    "pt-MO": "left-to-right",
    "pt-MZ": "left-to-right",
    "pt-PT": "left-to-right",
    "pt-ST": "left-to-right",
    "pt-TL": "left-to-right",
    "qu": "left-to-right",
    "qu-BO": "left-to-right",
    "qu-EC": "left-to-right",
    "quc": "left-to-right",
    "raj": "left-to-right",
    "rhg": "right-to-left",
    "rhg-Rohg": "right-to-left",
    "rhg-Rohg-BD": "right-to-left",
    "rif": "left-to-right",
    "rm": "left-to-right",
    "rn": "left-to-right",
    "ro": "left-to-right",
    "ro-MD": "left-to-right",
    "rof": "left-to-right",
    "ru": "left-to-right",
    "ru-BY": "left-to-right",
    "ru-KG": "left-to-right",
    "ru-KZ": "left-to-right",
    "ru-MD": "left-to-right",
    "ru-UA": "left-to-right",
    "rw": "left-to-right",
    "rwk": "left-to-right",
    "sa": "left-to-right",
    "sah": "left-to-right",
    "saq": "left-to-right",
    "sat": "left-to-right",
    "sat-Deva": "left-to-right",
    "sat-Olck": "left-to-right",
    "sbp": "left-to-right",
    "sc": "left-to-right",
    "scn": "left-to-right",
    "sd": "right-to-left",
    "sd-Arab": "right-to-left",
    "sd-Deva": "left-to-right",
    "sdh": "right-to-left",
    "sdh-IQ": "right-to-left",
    "se": "left-to-right",
    "se-FI": "left-to-right",
    "se-SE": "left-to-right",
    "seh": "left-to-right",
    "ses": "left-to-right",
    "sg": "left-to-right",
    "shi": "left-to-right",
    "shi-Latn": "left-to-right",
    "shi-Tfng": "left-to-right",
    "shn": "left-to-right",
    "shn-TH": "left-to-right",
    "si": "left-to-right",
    "sid": "left-to-right",
    "sk": "left-to-right",
    "skr": "right-to-left",
    "sl": "left-to-right",
    "sma": "left-to-right",
    "sma-NO": "left-to-right",
    "smj": "left-to-right",
    "smj-NO": "left-to-right",
    "smn": "left-to-right",
    "sms": "left-to-right",
    "sn": "left-to-right",
    "so": "left-to-right",
    "so-DJ": "left-to-right",
    "so-ET": "left-to-right",
    "so-KE": "left-to-right",
    "sq": "left-to-right",
    "sq-MK": "left-to-right",
    "sq-XK": "left-to-right",
    "sr": "left-to-right",
    "sr-Cyrl": "left-to-right",
    "sr-Cyrl-BA": "left-to-right",
    "sr-Cyrl-ME": "left-to-right",
    "sr-Cyrl-XK": "left-to-right",
    "sr-Latn": "left-to-right",
    "sr-Latn-BA": "left-to-right",
    "sr-Latn-ME": "left-to-right",
    "sr-Latn-XK": "left-to-right",
    "ss": "left-to-right",
    "ss-SZ": "left-to-right",
    "ssy": "left-to-right",
    "st": "left-to-right",
    "st-LS": "left-to-right",
    "su": "left-to-right",
    "su-Latn": "left-to-right",
    "sv": "left-to-right",
    "sv-AX": "left-to-right",
    "sv-FI": "left-to-right",
    "sw": "left-to-right",
    "sw-CD": "left-to-right",
    "sw-KE": "left-to-right",
    "sw-UG": "left-to-right",
    "syr": "right-to-left",
    "syr-SY": "right-to-left",
    "szl": "left-to-right",
    "ta": "left-to-right",
    "ta-LK": "left-to-right",
    "ta-MY": "left-to-right",
    "ta-SG": "left-to-right",
    "te": "left-to-right",
    "teo": "left-to-right",
    "teo-KE": "left-to-right",
    "tg": "left-to-right",
    "th": "left-to-right",
    "ti": "left-to-right",
    "ti-ER": "left-to-right",
    "tig": "left-to-right",
    "tk": "left-to-right",
    "tn": "left-to-right",
    "tn-BW": "left-to-right",
    "to": "left-to-right",
    "tok": "left-to-right",
    "tpi": "left-to-right",
    "tr": "left-to-right",
    "tr-CY": "left-to-right",
    "trv": "left-to-right",
    "trw": "right-to-left",
    "ts": "left-to-right",
    "tt": "left-to-right",
    "twq": "left-to-right",
    "tyv": "left-to-right",
    "tzm": "left-to-right",
    "ug": "right-to-left",
    "uk": "left-to-right",
    "und": "left-to-right",
    "ur": "right-to-left",
    "ur-IN": "right-to-left",
    "uz": "left-to-right",
    "uz-Arab": "right-to-left",
    "uz-Cyrl": "left-to-right",
    "uz-Latn": "left-to-right",
    "vai": "left-to-right",
    "vai-Latn": "left-to-right",
    "vai-Vaii": "left-to-right",
    "ve": "left-to-right",
    "vec": "left-to-right",
    "vi": "left-to-right",
    "vmw": "left-to-right",
    "vo": "left-to-right",
    "vun": "left-to-right",
    "wa": "left-to-right",
    "wae": "left-to-right",
    "wal": "left-to-right",
    "wbp": "left-to-right",
    "wo": "left-to-right",
    "xh": "left-to-right",
    "xnr": "left-to-right",
    "xog": "left-to-right",
    "yav": "left-to-right",
    "yi": "right-to-left",
    "yo": "left-to-right",
    "yo-BJ": "left-to-right",
    "yrl": "left-to-right",
    "yrl-CO": "left-to-right",
    "yrl-VE": "left-to-right",
    "yue": "left-to-right",
    "yue-Hans": "left-to-right",
    "yue-Hant": "left-to-right",
    "yue-Hant-CN": "left-to-right",
    "za": "left-to-right",
    "zgh": "left-to-right",
    "zh": "left-to-right",
    "zh-Hans": "left-to-right",
    "zh-Hans-HK": "left-to-right",
    "zh-Hans-MO": "left-to-right",
    "zh-Hans-MY": "left-to-right",
    "zh-Hans-SG": "left-to-right",
    "zh-Hant": "left-to-right",
    "zh-Hant-HK": "left-to-right",
    "zh-Hant-MO": "left-to-right",
    "zh-Hant-MY": "left-to-right",
    "zh-Latn": "left-to-right",
    "zu": "left-to-right"
};
