"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getSupportedTimeZones = getSupportedTimeZones;
var ecma402_abstract_1 = require("@formatjs/ecma402-abstract");
var timezones_generated_1 = require("./timezones.generated");
function isSupported(timeZone, locale) {
    if (locale === void 0) { locale = 'en'; }
    try {
        var formatter = (0, ecma402_abstract_1.createMemoizedDateTimeFormat)(locale, { timeZone: timeZone });
        return formatter.resolvedOptions().timeZone === timeZone;
    }
    catch (_err) { }
    return false;
}
function getSupportedTimeZones(locale) {
    return timezones_generated_1.timezones.filter(function (timezone) { return isSupported(timezone, locale); });
}
