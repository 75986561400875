/**
 * @description Unable to convert to const enums due to implementation
 */
export enum ECurrency {
	EUR = 'EUR',
	GEL = 'GEL',
	RUB = 'RUB',
	USD = 'USD',
	THB = 'THB',
}

export type TCurrencyRates = Record<ECurrency, number>;
