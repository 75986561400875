import { ELocaleCode } from '@root/core/modules/intl/domain/enums';

export const expenseFormI18n = {
	[ELocaleCode.RU_RU]: {
		submitButton: '→',
	},
	[ELocaleCode.EN_US]: {
		submitButton: '→',
	},
};
