import { ELocaleCode } from '@root/core/modules/intl/domain/enums';

export const notFoundErrorScreenI18n = {
	[ELocaleCode.RU_RU]: {
		pageNotFound: 'Страница не найдена',
	},
	[ELocaleCode.EN_US]: {
		pageNotFound: 'Page not found',
	},
};
