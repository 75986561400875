import { ELocaleCode } from '@root/core/modules/intl/domain/enums';

export const expenseListI18n = {
	[ELocaleCode.RU_RU]: {
		title: 'Расходы',
	},
	[ELocaleCode.EN_US]: {
		title: 'Expenses',
	},
};
