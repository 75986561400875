import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { EServiceToken } from '@root/core/infrastructure/serviceContainer/types';
import { useService } from '@root/presentation/web-ui/modules/serviceContainer/useService';

import { useAppNavigation } from '@root/presentation/web-ui/hooks/useNavigation';

import type { Id } from '@root/core/domain/types';
import type { TVenueDTO } from '@root/core/modules/venue/application/dto/venueDTO';

export const VENUE_QUERY_LIST = 'venueQueryList';
export const VENUE_QUERY_BY_ID = 'venueQueryById';

function createVenueQuery(type: 'list'): () => [];
function createVenueQuery(type: 'oneById'): (id: Id) => [];
function createVenueQuery(type: 'list' | 'oneById') {
	switch (type) {
		case 'list':
			return () => [VENUE_QUERY_LIST];
		case 'oneById':
			return (id: Id) => [VENUE_QUERY_BY_ID, id];
		default:
			let invalidType: never = type;
			throw new Error(`Unknown venue query type: ${invalidType}`);
	}
}

export function useVenueListQuery(): (TVenueDTO & {
	categoryName: string;
})[] {
	let venueQueryService = useService(EServiceToken.VENUE_QUERY_SERVICE);
	let categoryService = useService(EServiceToken.CATEGORY_SERVICE);

	let { data } = useQuery({
		queryKey: createVenueQuery('list')(),
		queryFn: () => {
			let venues = venueQueryService.getAll();

			return venues.map((venue) => ({
				...venue,
				categoryName: categoryService.getCategoryName(venue.categoryId),
			}));
		},
		refetchOnMount: false,
	});

	return data ?? [];
}

export function useVenueQueryById(
	id: Id,
): (TVenueDTO & { categoryName: string }) | null {
	let venueQueryService = useService(EServiceToken.VENUE_QUERY_SERVICE);
	let categoryService = useService(EServiceToken.CATEGORY_SERVICE);

	let { data } = useQuery({
		queryKey: createVenueQuery('oneById')(id),
		queryFn: () => {
			let venue = venueQueryService.getById(id);

			if (!venue) {
				return null;
			}

			let categoryName = categoryService.getCategoryName(venue.categoryId);

			return {
				...venue,
				categoryName,
			};
		},
		refetchOnMount: false,
	});

	return data ?? null;
}

export function useVenueRemoveMutation() {
	let venueUseCase = useService(EServiceToken.VENUE_USE_CASE);
	let queryClient = useQueryClient();
	let navigation = useAppNavigation();

	let result = useMutation({
		mutationFn: async (id: Id) => {
			return venueUseCase.remove(id);
		},
		onSuccess: async (_, id) => {
			await Promise.all([
				queryClient.invalidateQueries({
					queryKey: createVenueQuery('list')(),
					refetchType: 'all',
				}),
				queryClient.invalidateQueries({
					queryKey: createVenueQuery('oneById')(id),
					refetchType: 'all',
				}),
			]);

			navigation.venueListPage();
		},
	});

	return result;
}
