import { ELocaleCode } from '@root/core/modules/intl/domain/enums';

export const errorScreenI18n = {
	[ELocaleCode.RU_RU]: {
		defaultTitle: 'Ошибка',
	},
	[ELocaleCode.EN_US]: {
		defaultTitle: 'Error',
	},
};
