"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.numberingSystems = void 0;
/* @generated */
// prettier-ignore
exports.numberingSystems = {
    "aa": [
        "latn"
    ],
    "aa-DJ": [
        "latn"
    ],
    "aa-ER": [
        "latn"
    ],
    "ab": [
        "latn"
    ],
    "af": [
        "latn"
    ],
    "af-NA": [
        "latn"
    ],
    "agq": [
        "latn"
    ],
    "ak": [
        "latn"
    ],
    "am": [
        "latn",
        "ethi"
    ],
    "an": [
        "latn"
    ],
    "ann": [
        "latn"
    ],
    "apc": [
        "latn"
    ],
    "ar": [
        "latn",
        "arab"
    ],
    "ar-AE": [
        "latn",
        "arab"
    ],
    "ar-BH": [
        "arab"
    ],
    "ar-DJ": [
        "arab"
    ],
    "ar-DZ": [
        "latn",
        "arab"
    ],
    "ar-EG": [
        "arab"
    ],
    "ar-EH": [
        "latn",
        "arab"
    ],
    "ar-ER": [
        "arab"
    ],
    "ar-IL": [
        "arab"
    ],
    "ar-IQ": [
        "arab"
    ],
    "ar-JO": [
        "arab"
    ],
    "ar-KM": [
        "arab"
    ],
    "ar-KW": [
        "arab"
    ],
    "ar-LB": [
        "arab"
    ],
    "ar-LY": [
        "latn",
        "arab"
    ],
    "ar-MA": [
        "latn",
        "arab"
    ],
    "ar-MR": [
        "arab"
    ],
    "ar-OM": [
        "arab"
    ],
    "ar-PS": [
        "arab"
    ],
    "ar-QA": [
        "arab"
    ],
    "ar-SA": [
        "arab"
    ],
    "ar-SD": [
        "arab"
    ],
    "ar-SO": [
        "arab"
    ],
    "ar-SS": [
        "arab"
    ],
    "ar-SY": [
        "arab"
    ],
    "ar-TD": [
        "arab"
    ],
    "ar-TN": [
        "latn",
        "arab"
    ],
    "ar-YE": [
        "arab"
    ],
    "arn": [
        "latn"
    ],
    "as": [
        "beng"
    ],
    "asa": [
        "latn"
    ],
    "ast": [
        "latn"
    ],
    "az": [
        "latn"
    ],
    "az-Arab": [
        "arabext"
    ],
    "az-Arab-IQ": [
        "arabext"
    ],
    "az-Arab-TR": [
        "arabext"
    ],
    "az-Cyrl": [
        "latn"
    ],
    "az-Latn": [
        "latn"
    ],
    "ba": [
        "latn"
    ],
    "bal": [
        "latn"
    ],
    "bal-Arab": [
        "latn"
    ],
    "bal-Latn": [
        "latn"
    ],
    "bas": [
        "latn"
    ],
    "be": [
        "latn"
    ],
    "be-tarask": [
        "latn"
    ],
    "bem": [
        "latn"
    ],
    "bew": [
        "latn"
    ],
    "bez": [
        "latn"
    ],
    "bg": [
        "latn"
    ],
    "bgc": [
        "deva",
        "latn"
    ],
    "bgn": [
        "arabext"
    ],
    "bgn-AE": [
        "arabext"
    ],
    "bgn-AF": [
        "arabext"
    ],
    "bgn-IR": [
        "arabext"
    ],
    "bgn-OM": [
        "arabext"
    ],
    "bho": [
        "deva",
        "latn"
    ],
    "blo": [
        "latn"
    ],
    "blt": [
        "latn"
    ],
    "bm": [
        "latn"
    ],
    "bm-Nkoo": [
        "latn",
        "nkoo"
    ],
    "bn": [
        "beng"
    ],
    "bn-IN": [
        "beng"
    ],
    "bo": [
        "latn",
        "tibt"
    ],
    "bo-IN": [
        "latn",
        "tibt"
    ],
    "br": [
        "latn"
    ],
    "brx": [
        "latn",
        "deva"
    ],
    "bs": [
        "latn"
    ],
    "bs-Cyrl": [
        "latn"
    ],
    "bs-Latn": [
        "latn"
    ],
    "bss": [
        "latn"
    ],
    "byn": [
        "latn",
        "ethi"
    ],
    "ca": [
        "latn"
    ],
    "ca-AD": [
        "latn"
    ],
    "ca-ES-valencia": [
        "latn"
    ],
    "ca-FR": [
        "latn"
    ],
    "ca-IT": [
        "latn"
    ],
    "cad": [
        "latn"
    ],
    "cch": [
        "latn"
    ],
    "ccp": [
        "cakm"
    ],
    "ccp-IN": [
        "cakm"
    ],
    "ce": [
        "latn"
    ],
    "ceb": [
        "latn"
    ],
    "cgg": [
        "latn"
    ],
    "cho": [
        "latn"
    ],
    "chr": [
        "latn"
    ],
    "cic": [
        "latn"
    ],
    "ckb": [
        "arab"
    ],
    "ckb-IR": [
        "arab"
    ],
    "co": [
        "latn"
    ],
    "cs": [
        "latn"
    ],
    "csw": [
        "latn"
    ],
    "cu": [
        "latn",
        "cyrl"
    ],
    "cv": [
        "latn"
    ],
    "cy": [
        "latn"
    ],
    "da": [
        "latn"
    ],
    "da-GL": [
        "latn"
    ],
    "dav": [
        "latn"
    ],
    "de": [
        "latn"
    ],
    "de-AT": [
        "latn"
    ],
    "de-BE": [
        "latn"
    ],
    "de-CH": [
        "latn"
    ],
    "de-IT": [
        "latn"
    ],
    "de-LI": [
        "latn"
    ],
    "de-LU": [
        "latn"
    ],
    "dje": [
        "latn"
    ],
    "doi": [
        "latn",
        "deva"
    ],
    "dsb": [
        "latn"
    ],
    "dua": [
        "latn"
    ],
    "dv": [
        "latn",
        "arab"
    ],
    "dyo": [
        "latn"
    ],
    "dz": [
        "tibt"
    ],
    "ebu": [
        "latn"
    ],
    "ee": [
        "latn"
    ],
    "ee-TG": [
        "latn"
    ],
    "el": [
        "latn",
        "grek"
    ],
    "el-CY": [
        "latn",
        "grek"
    ],
    "el-polyton": [
        "latn",
        "grek"
    ],
    "en": [
        "latn"
    ],
    "en-001": [
        "latn"
    ],
    "en-150": [
        "latn"
    ],
    "en-AE": [
        "latn"
    ],
    "en-AG": [
        "latn"
    ],
    "en-AI": [
        "latn"
    ],
    "en-AS": [
        "latn"
    ],
    "en-AT": [
        "latn"
    ],
    "en-AU": [
        "latn"
    ],
    "en-BB": [
        "latn"
    ],
    "en-BE": [
        "latn"
    ],
    "en-BI": [
        "latn"
    ],
    "en-BM": [
        "latn"
    ],
    "en-BS": [
        "latn"
    ],
    "en-BW": [
        "latn"
    ],
    "en-BZ": [
        "latn"
    ],
    "en-CA": [
        "latn"
    ],
    "en-CC": [
        "latn"
    ],
    "en-CH": [
        "latn"
    ],
    "en-CK": [
        "latn"
    ],
    "en-CM": [
        "latn"
    ],
    "en-CX": [
        "latn"
    ],
    "en-CY": [
        "latn"
    ],
    "en-DE": [
        "latn"
    ],
    "en-DG": [
        "latn"
    ],
    "en-DK": [
        "latn"
    ],
    "en-DM": [
        "latn"
    ],
    "en-Dsrt": [
        "latn"
    ],
    "en-ER": [
        "latn"
    ],
    "en-FI": [
        "latn"
    ],
    "en-FJ": [
        "latn"
    ],
    "en-FK": [
        "latn"
    ],
    "en-FM": [
        "latn"
    ],
    "en-GB": [
        "latn"
    ],
    "en-GD": [
        "latn"
    ],
    "en-GG": [
        "latn"
    ],
    "en-GH": [
        "latn"
    ],
    "en-GI": [
        "latn"
    ],
    "en-GM": [
        "latn"
    ],
    "en-GU": [
        "latn"
    ],
    "en-GY": [
        "latn"
    ],
    "en-HK": [
        "latn"
    ],
    "en-ID": [
        "latn"
    ],
    "en-IE": [
        "latn"
    ],
    "en-IL": [
        "latn"
    ],
    "en-IM": [
        "latn"
    ],
    "en-IN": [
        "latn"
    ],
    "en-IO": [
        "latn"
    ],
    "en-JE": [
        "latn"
    ],
    "en-JM": [
        "latn"
    ],
    "en-KE": [
        "latn"
    ],
    "en-KI": [
        "latn"
    ],
    "en-KN": [
        "latn"
    ],
    "en-KY": [
        "latn"
    ],
    "en-LC": [
        "latn"
    ],
    "en-LR": [
        "latn"
    ],
    "en-LS": [
        "latn"
    ],
    "en-MG": [
        "latn"
    ],
    "en-MH": [
        "latn"
    ],
    "en-MO": [
        "latn"
    ],
    "en-MP": [
        "latn"
    ],
    "en-MS": [
        "latn"
    ],
    "en-MT": [
        "latn"
    ],
    "en-MU": [
        "latn"
    ],
    "en-MV": [
        "latn"
    ],
    "en-MW": [
        "latn"
    ],
    "en-MY": [
        "latn"
    ],
    "en-NA": [
        "latn"
    ],
    "en-NF": [
        "latn"
    ],
    "en-NG": [
        "latn"
    ],
    "en-NL": [
        "latn"
    ],
    "en-NR": [
        "latn"
    ],
    "en-NU": [
        "latn"
    ],
    "en-NZ": [
        "latn"
    ],
    "en-PG": [
        "latn"
    ],
    "en-PH": [
        "latn"
    ],
    "en-PK": [
        "latn"
    ],
    "en-PN": [
        "latn"
    ],
    "en-PR": [
        "latn"
    ],
    "en-PW": [
        "latn"
    ],
    "en-RW": [
        "latn"
    ],
    "en-SB": [
        "latn"
    ],
    "en-SC": [
        "latn"
    ],
    "en-SD": [
        "latn"
    ],
    "en-SE": [
        "latn"
    ],
    "en-SG": [
        "latn"
    ],
    "en-SH": [
        "latn"
    ],
    "en-SI": [
        "latn"
    ],
    "en-SL": [
        "latn"
    ],
    "en-SS": [
        "latn"
    ],
    "en-SX": [
        "latn"
    ],
    "en-SZ": [
        "latn"
    ],
    "en-Shaw": [
        "latn"
    ],
    "en-TC": [
        "latn"
    ],
    "en-TK": [
        "latn"
    ],
    "en-TO": [
        "latn"
    ],
    "en-TT": [
        "latn"
    ],
    "en-TV": [
        "latn"
    ],
    "en-TZ": [
        "latn"
    ],
    "en-UG": [
        "latn"
    ],
    "en-UM": [
        "latn"
    ],
    "en-VC": [
        "latn"
    ],
    "en-VG": [
        "latn"
    ],
    "en-VI": [
        "latn"
    ],
    "en-VU": [
        "latn"
    ],
    "en-WS": [
        "latn"
    ],
    "en-ZA": [
        "latn"
    ],
    "en-ZM": [
        "latn"
    ],
    "en-ZW": [
        "latn"
    ],
    "eo": [
        "latn"
    ],
    "es": [
        "latn"
    ],
    "es-419": [
        "latn"
    ],
    "es-AR": [
        "latn"
    ],
    "es-BO": [
        "latn"
    ],
    "es-BR": [
        "latn"
    ],
    "es-BZ": [
        "latn"
    ],
    "es-CL": [
        "latn"
    ],
    "es-CO": [
        "latn"
    ],
    "es-CR": [
        "latn"
    ],
    "es-CU": [
        "latn"
    ],
    "es-DO": [
        "latn"
    ],
    "es-EA": [
        "latn"
    ],
    "es-EC": [
        "latn"
    ],
    "es-GQ": [
        "latn"
    ],
    "es-GT": [
        "latn"
    ],
    "es-HN": [
        "latn"
    ],
    "es-IC": [
        "latn"
    ],
    "es-MX": [
        "latn"
    ],
    "es-NI": [
        "latn"
    ],
    "es-PA": [
        "latn"
    ],
    "es-PE": [
        "latn"
    ],
    "es-PH": [
        "latn"
    ],
    "es-PR": [
        "latn"
    ],
    "es-PY": [
        "latn"
    ],
    "es-SV": [
        "latn"
    ],
    "es-US": [
        "latn"
    ],
    "es-UY": [
        "latn"
    ],
    "es-VE": [
        "latn"
    ],
    "et": [
        "latn"
    ],
    "eu": [
        "latn"
    ],
    "ewo": [
        "latn"
    ],
    "fa": [
        "arabext"
    ],
    "fa-AF": [
        "arabext"
    ],
    "ff": [
        "latn"
    ],
    "ff-Adlm": [
        "adlm"
    ],
    "ff-Adlm-BF": [
        "adlm"
    ],
    "ff-Adlm-CM": [
        "adlm"
    ],
    "ff-Adlm-GH": [
        "adlm"
    ],
    "ff-Adlm-GM": [
        "adlm"
    ],
    "ff-Adlm-GW": [
        "adlm"
    ],
    "ff-Adlm-LR": [
        "adlm"
    ],
    "ff-Adlm-MR": [
        "adlm"
    ],
    "ff-Adlm-NE": [
        "adlm"
    ],
    "ff-Adlm-NG": [
        "adlm"
    ],
    "ff-Adlm-SL": [
        "adlm"
    ],
    "ff-Adlm-SN": [
        "adlm"
    ],
    "ff-Latn": [
        "latn"
    ],
    "ff-Latn-BF": [
        "latn"
    ],
    "ff-Latn-CM": [
        "latn"
    ],
    "ff-Latn-GH": [
        "latn"
    ],
    "ff-Latn-GM": [
        "latn"
    ],
    "ff-Latn-GN": [
        "latn"
    ],
    "ff-Latn-GW": [
        "latn"
    ],
    "ff-Latn-LR": [
        "latn"
    ],
    "ff-Latn-MR": [
        "latn"
    ],
    "ff-Latn-NE": [
        "latn"
    ],
    "ff-Latn-NG": [
        "latn"
    ],
    "ff-Latn-SL": [
        "latn"
    ],
    "fi": [
        "latn"
    ],
    "fil": [
        "latn"
    ],
    "fo": [
        "latn"
    ],
    "fo-DK": [
        "latn"
    ],
    "fr": [
        "latn"
    ],
    "fr-BE": [
        "latn"
    ],
    "fr-BF": [
        "latn"
    ],
    "fr-BI": [
        "latn"
    ],
    "fr-BJ": [
        "latn"
    ],
    "fr-BL": [
        "latn"
    ],
    "fr-CA": [
        "latn"
    ],
    "fr-CD": [
        "latn"
    ],
    "fr-CF": [
        "latn"
    ],
    "fr-CG": [
        "latn"
    ],
    "fr-CH": [
        "latn"
    ],
    "fr-CI": [
        "latn"
    ],
    "fr-CM": [
        "latn"
    ],
    "fr-DJ": [
        "latn"
    ],
    "fr-DZ": [
        "latn"
    ],
    "fr-GA": [
        "latn"
    ],
    "fr-GF": [
        "latn"
    ],
    "fr-GN": [
        "latn"
    ],
    "fr-GP": [
        "latn"
    ],
    "fr-GQ": [
        "latn"
    ],
    "fr-HT": [
        "latn"
    ],
    "fr-KM": [
        "latn"
    ],
    "fr-LU": [
        "latn"
    ],
    "fr-MA": [
        "latn"
    ],
    "fr-MC": [
        "latn"
    ],
    "fr-MF": [
        "latn"
    ],
    "fr-MG": [
        "latn"
    ],
    "fr-ML": [
        "latn"
    ],
    "fr-MQ": [
        "latn"
    ],
    "fr-MR": [
        "latn"
    ],
    "fr-MU": [
        "latn"
    ],
    "fr-NC": [
        "latn"
    ],
    "fr-NE": [
        "latn"
    ],
    "fr-PF": [
        "latn"
    ],
    "fr-PM": [
        "latn"
    ],
    "fr-RE": [
        "latn"
    ],
    "fr-RW": [
        "latn"
    ],
    "fr-SC": [
        "latn"
    ],
    "fr-SN": [
        "latn"
    ],
    "fr-SY": [
        "latn"
    ],
    "fr-TD": [
        "latn"
    ],
    "fr-TG": [
        "latn"
    ],
    "fr-TN": [
        "latn"
    ],
    "fr-VU": [
        "latn"
    ],
    "fr-WF": [
        "latn"
    ],
    "fr-YT": [
        "latn"
    ],
    "frr": [
        "latn"
    ],
    "fur": [
        "latn"
    ],
    "fy": [
        "latn"
    ],
    "ga": [
        "latn"
    ],
    "ga-GB": [
        "latn"
    ],
    "gaa": [
        "latn"
    ],
    "gd": [
        "latn"
    ],
    "gez": [
        "latn"
    ],
    "gez-ER": [
        "latn"
    ],
    "gl": [
        "latn"
    ],
    "gn": [
        "latn"
    ],
    "gsw": [
        "latn"
    ],
    "gsw-FR": [
        "latn"
    ],
    "gsw-LI": [
        "latn"
    ],
    "gu": [
        "latn",
        "gujr"
    ],
    "guz": [
        "latn"
    ],
    "gv": [
        "latn"
    ],
    "ha": [
        "latn"
    ],
    "ha-Arab": [
        "latn",
        "arab"
    ],
    "ha-Arab-SD": [
        "latn",
        "arab"
    ],
    "ha-GH": [
        "latn"
    ],
    "ha-NE": [
        "latn"
    ],
    "haw": [
        "latn"
    ],
    "he": [
        "latn",
        "hebr"
    ],
    "hi": [
        "latn",
        "deva"
    ],
    "hi-Latn": [
        "latn"
    ],
    "hnj": [
        "hmnp",
        "latn"
    ],
    "hnj-Hmnp": [
        "hmnp",
        "latn"
    ],
    "hr": [
        "latn"
    ],
    "hr-BA": [
        "latn"
    ],
    "hsb": [
        "latn"
    ],
    "hu": [
        "latn"
    ],
    "hy": [
        "latn",
        "armn"
    ],
    "ia": [
        "latn"
    ],
    "id": [
        "latn"
    ],
    "ie": [
        "latn"
    ],
    "ig": [
        "latn"
    ],
    "ii": [
        "latn"
    ],
    "io": [
        "latn"
    ],
    "is": [
        "latn"
    ],
    "it": [
        "latn"
    ],
    "it-CH": [
        "latn"
    ],
    "it-SM": [
        "latn"
    ],
    "it-VA": [
        "latn"
    ],
    "iu": [
        "latn"
    ],
    "iu-Latn": [
        "latn"
    ],
    "ja": [
        "latn",
        "jpan",
        "jpanfin"
    ],
    "jbo": [
        "latn"
    ],
    "jgo": [
        "latn"
    ],
    "jmc": [
        "latn"
    ],
    "jv": [
        "latn",
        "java"
    ],
    "ka": [
        "latn",
        "geor"
    ],
    "kaa": [
        "latn"
    ],
    "kaa-Cyrl": [
        "latn"
    ],
    "kaa-Latn": [
        "latn"
    ],
    "kab": [
        "latn"
    ],
    "kaj": [
        "latn"
    ],
    "kam": [
        "latn"
    ],
    "kcg": [
        "latn"
    ],
    "kde": [
        "latn"
    ],
    "kea": [
        "latn"
    ],
    "ken": [
        "latn"
    ],
    "kgp": [
        "latn"
    ],
    "khq": [
        "latn"
    ],
    "ki": [
        "latn"
    ],
    "kk": [
        "latn"
    ],
    "kk-Arab": [
        "latn"
    ],
    "kk-Cyrl": [
        "latn"
    ],
    "kk-KZ": [
        "latn"
    ],
    "kkj": [
        "latn"
    ],
    "kl": [
        "latn"
    ],
    "kln": [
        "latn"
    ],
    "km": [
        "latn",
        "khmr"
    ],
    "kn": [
        "latn",
        "knda"
    ],
    "ko": [
        "latn"
    ],
    "ko-CN": [
        "latn"
    ],
    "ko-KP": [
        "latn"
    ],
    "kok": [
        "latn",
        "deva"
    ],
    "kok-Deva": [
        "latn",
        "deva"
    ],
    "kok-Latn": [
        "latn"
    ],
    "kpe": [
        "latn"
    ],
    "kpe-GN": [
        "latn"
    ],
    "ks": [
        "arabext"
    ],
    "ks-Arab": [
        "arabext"
    ],
    "ks-Deva": [
        "latn"
    ],
    "ksb": [
        "latn"
    ],
    "ksf": [
        "latn"
    ],
    "ksh": [
        "latn"
    ],
    "ku": [
        "latn"
    ],
    "kw": [
        "latn"
    ],
    "kxv": [
        "latn"
    ],
    "kxv-Deva": [
        "latn",
        "deva"
    ],
    "kxv-Latn": [
        "latn"
    ],
    "kxv-Orya": [
        "latn",
        "orya"
    ],
    "kxv-Telu": [
        "latn",
        "telu"
    ],
    "ky": [
        "latn"
    ],
    "la": [
        "latn"
    ],
    "lag": [
        "latn"
    ],
    "lb": [
        "latn"
    ],
    "lg": [
        "latn"
    ],
    "lij": [
        "latn"
    ],
    "lkt": [
        "latn"
    ],
    "lld": [
        "latn"
    ],
    "lmo": [
        "latn"
    ],
    "ln": [
        "latn"
    ],
    "ln-AO": [
        "latn"
    ],
    "ln-CF": [
        "latn"
    ],
    "ln-CG": [
        "latn"
    ],
    "lo": [
        "latn",
        "laoo"
    ],
    "lrc": [
        "arabext"
    ],
    "lrc-IQ": [
        "arabext"
    ],
    "lt": [
        "latn"
    ],
    "ltg": [
        "latn"
    ],
    "lu": [
        "latn"
    ],
    "luo": [
        "latn"
    ],
    "luy": [
        "latn"
    ],
    "lv": [
        "latn"
    ],
    "mai": [
        "latn",
        "deva"
    ],
    "mas": [
        "latn"
    ],
    "mas-TZ": [
        "latn"
    ],
    "mdf": [
        "latn"
    ],
    "mer": [
        "latn"
    ],
    "mfe": [
        "latn"
    ],
    "mg": [
        "latn"
    ],
    "mgh": [
        "latn"
    ],
    "mgo": [
        "latn"
    ],
    "mhn": [
        "latn"
    ],
    "mi": [
        "latn"
    ],
    "mic": [
        "latn"
    ],
    "mk": [
        "latn"
    ],
    "ml": [
        "latn",
        "mlym"
    ],
    "mn": [
        "latn"
    ],
    "mn-Mong": [
        "latn",
        "mong"
    ],
    "mn-Mong-MN": [
        "latn",
        "mong"
    ],
    "mni": [
        "beng",
        "latn"
    ],
    "mni-Beng": [
        "beng",
        "latn"
    ],
    "mni-Mtei": [
        "mtei",
        "latn"
    ],
    "moh": [
        "latn"
    ],
    "mr": [
        "deva"
    ],
    "ms": [
        "latn"
    ],
    "ms-Arab": [
        "latn"
    ],
    "ms-Arab-BN": [
        "latn"
    ],
    "ms-BN": [
        "latn"
    ],
    "ms-ID": [
        "latn"
    ],
    "ms-SG": [
        "latn"
    ],
    "mt": [
        "latn"
    ],
    "mua": [
        "latn"
    ],
    "mus": [
        "latn"
    ],
    "my": [
        "mymr"
    ],
    "myv": [
        "latn"
    ],
    "mzn": [
        "arabext"
    ],
    "naq": [
        "latn"
    ],
    "nb": [
        "latn"
    ],
    "nb-SJ": [
        "latn"
    ],
    "nd": [
        "latn"
    ],
    "nds": [
        "latn"
    ],
    "nds-NL": [
        "latn"
    ],
    "ne": [
        "deva"
    ],
    "ne-IN": [
        "deva"
    ],
    "nl": [
        "latn"
    ],
    "nl-AW": [
        "latn"
    ],
    "nl-BE": [
        "latn"
    ],
    "nl-BQ": [
        "latn"
    ],
    "nl-CW": [
        "latn"
    ],
    "nl-SR": [
        "latn"
    ],
    "nl-SX": [
        "latn"
    ],
    "nmg": [
        "latn"
    ],
    "nn": [
        "latn"
    ],
    "nnh": [
        "latn"
    ],
    "no": [
        "latn"
    ],
    "nqo": [
        "nkoo"
    ],
    "nr": [
        "latn"
    ],
    "nso": [
        "latn"
    ],
    "nus": [
        "latn"
    ],
    "nv": [
        "latn"
    ],
    "ny": [
        "latn"
    ],
    "nyn": [
        "latn"
    ],
    "oc": [
        "latn"
    ],
    "oc-ES": [
        "latn"
    ],
    "om": [
        "latn",
        "ethi"
    ],
    "om-KE": [
        "latn",
        "ethi"
    ],
    "or": [
        "latn",
        "orya"
    ],
    "os": [
        "latn"
    ],
    "os-RU": [
        "latn"
    ],
    "osa": [
        "latn"
    ],
    "pa": [
        "latn",
        "guru"
    ],
    "pa-Arab": [
        "arabext"
    ],
    "pa-Guru": [
        "latn",
        "guru"
    ],
    "pap": [
        "latn"
    ],
    "pap-AW": [
        "latn"
    ],
    "pcm": [
        "latn"
    ],
    "pis": [
        "latn"
    ],
    "pl": [
        "latn"
    ],
    "prg": [
        "latn"
    ],
    "ps": [
        "arabext"
    ],
    "ps-PK": [
        "arabext"
    ],
    "pt": [
        "latn"
    ],
    "pt-AO": [
        "latn"
    ],
    "pt-CH": [
        "latn"
    ],
    "pt-CV": [
        "latn"
    ],
    "pt-GQ": [
        "latn"
    ],
    "pt-GW": [
        "latn"
    ],
    "pt-LU": [
        "latn"
    ],
    "pt-MO": [
        "latn"
    ],
    "pt-MZ": [
        "latn"
    ],
    "pt-PT": [
        "latn"
    ],
    "pt-ST": [
        "latn"
    ],
    "pt-TL": [
        "latn"
    ],
    "qu": [
        "latn"
    ],
    "qu-BO": [
        "latn"
    ],
    "qu-EC": [
        "latn"
    ],
    "quc": [
        "latn"
    ],
    "raj": [
        "deva",
        "latn"
    ],
    "rhg": [
        "latn"
    ],
    "rhg-Rohg": [
        "latn"
    ],
    "rhg-Rohg-BD": [
        "latn"
    ],
    "rif": [
        "latn"
    ],
    "rm": [
        "latn"
    ],
    "rn": [
        "latn"
    ],
    "ro": [
        "latn"
    ],
    "ro-MD": [
        "latn"
    ],
    "rof": [
        "latn"
    ],
    "ru": [
        "latn"
    ],
    "ru-BY": [
        "latn"
    ],
    "ru-KG": [
        "latn"
    ],
    "ru-KZ": [
        "latn"
    ],
    "ru-MD": [
        "latn"
    ],
    "ru-UA": [
        "latn"
    ],
    "rw": [
        "latn"
    ],
    "rwk": [
        "latn"
    ],
    "sa": [
        "deva"
    ],
    "sah": [
        "latn"
    ],
    "saq": [
        "latn"
    ],
    "sat": [
        "olck",
        "latn"
    ],
    "sat-Deva": [
        "deva",
        "latn"
    ],
    "sat-Olck": [
        "olck",
        "latn"
    ],
    "sbp": [
        "latn"
    ],
    "sc": [
        "latn"
    ],
    "scn": [
        "latn"
    ],
    "sd": [
        "arab"
    ],
    "sd-Arab": [
        "arab"
    ],
    "sd-Deva": [
        "latn"
    ],
    "sdh": [
        "arab"
    ],
    "sdh-IQ": [
        "arab"
    ],
    "se": [
        "latn"
    ],
    "se-FI": [
        "latn"
    ],
    "se-SE": [
        "latn"
    ],
    "seh": [
        "latn"
    ],
    "ses": [
        "latn"
    ],
    "sg": [
        "latn"
    ],
    "shi": [
        "latn"
    ],
    "shi-Latn": [
        "latn"
    ],
    "shi-Tfng": [
        "latn"
    ],
    "shn": [
        "latn"
    ],
    "shn-TH": [
        "latn"
    ],
    "si": [
        "latn"
    ],
    "sid": [
        "latn"
    ],
    "sk": [
        "latn"
    ],
    "skr": [
        "latn"
    ],
    "sl": [
        "latn"
    ],
    "sma": [
        "latn"
    ],
    "sma-NO": [
        "latn"
    ],
    "smj": [
        "latn"
    ],
    "smj-NO": [
        "latn"
    ],
    "smn": [
        "latn"
    ],
    "sms": [
        "latn"
    ],
    "sn": [
        "latn"
    ],
    "so": [
        "latn"
    ],
    "so-DJ": [
        "latn"
    ],
    "so-ET": [
        "latn"
    ],
    "so-KE": [
        "latn"
    ],
    "sq": [
        "latn"
    ],
    "sq-MK": [
        "latn"
    ],
    "sq-XK": [
        "latn"
    ],
    "sr": [
        "latn"
    ],
    "sr-Cyrl": [
        "latn"
    ],
    "sr-Cyrl-BA": [
        "latn"
    ],
    "sr-Cyrl-ME": [
        "latn"
    ],
    "sr-Cyrl-XK": [
        "latn"
    ],
    "sr-Latn": [
        "latn"
    ],
    "sr-Latn-BA": [
        "latn"
    ],
    "sr-Latn-ME": [
        "latn"
    ],
    "sr-Latn-XK": [
        "latn"
    ],
    "ss": [
        "latn"
    ],
    "ss-SZ": [
        "latn"
    ],
    "ssy": [
        "latn"
    ],
    "st": [
        "latn"
    ],
    "st-LS": [
        "latn"
    ],
    "su": [
        "latn"
    ],
    "su-Latn": [
        "latn"
    ],
    "sv": [
        "latn"
    ],
    "sv-AX": [
        "latn"
    ],
    "sv-FI": [
        "latn"
    ],
    "sw": [
        "latn"
    ],
    "sw-CD": [
        "latn"
    ],
    "sw-KE": [
        "latn"
    ],
    "sw-UG": [
        "latn"
    ],
    "syr": [
        "latn"
    ],
    "syr-SY": [
        "latn"
    ],
    "szl": [
        "latn"
    ],
    "ta": [
        "latn",
        "tamldec",
        "taml"
    ],
    "ta-LK": [
        "latn",
        "tamldec",
        "taml"
    ],
    "ta-MY": [
        "latn",
        "tamldec",
        "taml"
    ],
    "ta-SG": [
        "latn",
        "tamldec",
        "taml"
    ],
    "te": [
        "latn",
        "telu"
    ],
    "teo": [
        "latn"
    ],
    "teo-KE": [
        "latn"
    ],
    "tg": [
        "latn"
    ],
    "th": [
        "latn",
        "thai"
    ],
    "ti": [
        "latn",
        "ethi"
    ],
    "ti-ER": [
        "latn",
        "ethi"
    ],
    "tig": [
        "latn",
        "ethi"
    ],
    "tk": [
        "latn"
    ],
    "tn": [
        "latn"
    ],
    "tn-BW": [
        "latn"
    ],
    "to": [
        "latn"
    ],
    "tok": [
        "latn"
    ],
    "tpi": [
        "latn"
    ],
    "tr": [
        "latn"
    ],
    "tr-CY": [
        "latn"
    ],
    "trv": [
        "latn"
    ],
    "trw": [
        "latn"
    ],
    "ts": [
        "latn"
    ],
    "tt": [
        "latn"
    ],
    "twq": [
        "latn"
    ],
    "tyv": [
        "latn"
    ],
    "tzm": [
        "latn"
    ],
    "ug": [
        "latn",
        "arabext"
    ],
    "uk": [
        "latn"
    ],
    "und": [
        "latn"
    ],
    "ur": [
        "latn",
        "arabext"
    ],
    "ur-IN": [
        "arabext"
    ],
    "uz": [
        "latn"
    ],
    "uz-Arab": [
        "arabext"
    ],
    "uz-Cyrl": [
        "latn"
    ],
    "uz-Latn": [
        "latn"
    ],
    "vai": [
        "latn",
        "vaii"
    ],
    "vai-Latn": [
        "latn",
        "vaii"
    ],
    "vai-Vaii": [
        "latn",
        "vaii"
    ],
    "ve": [
        "latn"
    ],
    "vec": [
        "latn"
    ],
    "vi": [
        "latn"
    ],
    "vmw": [
        "latn"
    ],
    "vo": [
        "latn"
    ],
    "vun": [
        "latn"
    ],
    "wa": [
        "latn"
    ],
    "wae": [
        "latn"
    ],
    "wal": [
        "latn",
        "ethi"
    ],
    "wbp": [
        "latn"
    ],
    "wo": [
        "latn"
    ],
    "xh": [
        "latn"
    ],
    "xnr": [
        "latn",
        "deva"
    ],
    "xog": [
        "latn"
    ],
    "yav": [
        "latn"
    ],
    "yi": [
        "latn",
        "hebr"
    ],
    "yo": [
        "latn"
    ],
    "yo-BJ": [
        "latn"
    ],
    "yrl": [
        "latn"
    ],
    "yrl-CO": [
        "latn"
    ],
    "yrl-VE": [
        "latn"
    ],
    "yue": [
        "latn",
        "hanidec",
        "hant",
        "hantfin"
    ],
    "yue-Hans": [
        "latn",
        "hanidec",
        "hans",
        "hansfin"
    ],
    "yue-Hant": [
        "latn",
        "hanidec",
        "hant",
        "hantfin"
    ],
    "yue-Hant-CN": [
        "latn",
        "hanidec",
        "hant",
        "hantfin"
    ],
    "za": [
        "latn"
    ],
    "zgh": [
        "latn"
    ],
    "zh": [
        "latn",
        "hanidec",
        "hans",
        "hansfin"
    ],
    "zh-Hans": [
        "latn",
        "hanidec",
        "hans",
        "hansfin"
    ],
    "zh-Hans-HK": [
        "latn",
        "hanidec",
        "hans",
        "hansfin"
    ],
    "zh-Hans-MO": [
        "latn",
        "hanidec",
        "hans",
        "hansfin"
    ],
    "zh-Hans-MY": [
        "latn",
        "hanidec",
        "hans",
        "hansfin"
    ],
    "zh-Hans-SG": [
        "latn",
        "hanidec",
        "hans",
        "hansfin"
    ],
    "zh-Hant": [
        "latn",
        "hanidec",
        "hant",
        "hantfin"
    ],
    "zh-Hant-HK": [
        "latn",
        "hanidec",
        "hant",
        "hantfin"
    ],
    "zh-Hant-MO": [
        "latn",
        "hanidec",
        "hant",
        "hantfin"
    ],
    "zh-Hant-MY": [
        "latn",
        "hanidec",
        "hant",
        "hantfin"
    ],
    "zh-Latn": [
        "latn"
    ],
    "zu": [
        "latn"
    ]
};
