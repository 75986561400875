"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getCalendarPreferenceDataForRegion = getCalendarPreferenceDataForRegion;
exports.getHourCyclesPreferenceDataForLocaleOrRegion = getHourCyclesPreferenceDataForLocaleOrRegion;
exports.getTimeZonePreferenceForRegion = getTimeZonePreferenceForRegion;
exports.getWeekDataForRegion = getWeekDataForRegion;
var tslib_1 = require("tslib");
var timezones_generated_1 = require("./timezones.generated");
var hour_cycles_generated_1 = require("./hour-cycles.generated");
var calendars_generated_1 = require("./calendars.generated");
var week_data_generated_1 = require("./week-data.generated");
function getCalendarPreferenceDataForRegion(region) {
    var _region = region ? region.toUpperCase() : null;
    return (calendars_generated_1.calendars[(_region || '')] || calendars_generated_1.calendars['001']).map(function (c) {
        // Resolve aliases
        // cldr-json/cldr-code v42.0.0-ALPHA3-2
        // https://github.com/unicode-org/cldr-json/blob/42.0.0-ALPHA3-2/cldr-json/cldr-bcp47/bcp47/calendar.json
        if (c === 'gregorian') {
            return 'gregory';
        }
        if (c === 'islamic-civil') {
            return 'islamicc';
        }
        // At the time this has been written this calendar was not listed in
        // the supplemental calendarPreferenceData file
        // https://github.com/unicode-org/cldr-json/blob/42.0.0-ALPHA3-2/cldr-json/cldr-core/supplemental/calendarPreferenceData.json
        // if (c === 'ethiopic-amete-alem') {
        //   return 'ethioaa'
        // }
        return c;
    });
}
function getHourCyclesPreferenceDataForLocaleOrRegion(locale, region) {
    var _locale = locale.toLowerCase();
    var _region = (region ? region.toUpperCase() : '');
    var hourCyclesPreference = hour_cycles_generated_1.hourCycles[_locale] ||
        hour_cycles_generated_1.hourCycles[_region] ||
        hour_cycles_generated_1.hourCycles["".concat(_locale, "-001")] ||
        hour_cycles_generated_1.hourCycles['001'];
    return tslib_1.__spreadArray([], hourCyclesPreference, true);
}
function getTimeZonePreferenceForRegion(region) {
    var territory = region.toLowerCase();
    if (timezones_generated_1.timezones[territory]) {
        return tslib_1.__spreadArray([], timezones_generated_1.timezones[territory], true);
    }
    return [];
}
function getWeekDataForRegion(region) {
    var _region = (region ? region.toUpperCase() : '');
    return week_data_generated_1.weekData[_region || '001'] || week_data_generated_1.weekData['001'];
}
