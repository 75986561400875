import { ELocaleCode } from '@root/core/modules/intl/domain/enums';

export const descriptionInputI18n = {
	[ELocaleCode.RU_RU]: {
		descriptionPlaceholder: 'Описание',
	},
	[ELocaleCode.EN_US]: {
		descriptionPlaceholder: 'Description',
	},
};
