import { LogInForm } from '@root/presentation/web-ui/modules/authentication/components/logInForm/logInForm';

import { Screen } from '@root/presentation/web-ui/uiKit/screen/screen';

export function LogInScreen() {
	return (
		<Screen>
			<LogInForm />
		</Screen>
	);
}
