"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getSupportedUnits = getSupportedUnits;
var ecma402_abstract_1 = require("@formatjs/ecma402-abstract");
var units_generated_1 = require("./units.generated");
function isSupported(unit, locale) {
    if (locale === void 0) { locale = 'en'; }
    try {
        var formatter = (0, ecma402_abstract_1.createMemoizedNumberFormat)(locale, { style: 'unit', unit: unit });
        return formatter.resolvedOptions().unit === unit;
    }
    catch (_err) { }
    return false;
}
function getSupportedUnits(locale) {
    return units_generated_1.units.filter(function (unit) { return isSupported(unit, locale); });
}
