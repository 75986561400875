"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.supportedValuesOf = supportedValuesOf;
var get_supported_calendars_1 = require("./get-supported-calendars");
var get_supported_collations_1 = require("./get-supported-collations");
var get_supported_currencies_1 = require("./get-supported-currencies");
var get_supported_numbering_systems_1 = require("./get-supported-numbering-systems");
var get_supported_timezones_1 = require("./get-supported-timezones");
var get_supported_units_1 = require("./get-supported-units");
function supportedValuesOf(key, locale) {
    switch (key) {
        case 'calendar':
            return (0, get_supported_calendars_1.getSupportedCalendars)(locale);
        case 'collation':
            return (0, get_supported_collations_1.getSupportedCollations)(locale);
        case 'currency':
            return (0, get_supported_currencies_1.getSupportedCurrencies)(locale);
        case 'numberingSystem':
            return (0, get_supported_numbering_systems_1.getSupportedNumberingSystems)(locale);
        case 'timeZone':
            return (0, get_supported_timezones_1.getSupportedTimeZones)(locale);
        case 'unit':
            return (0, get_supported_units_1.getSupportedUnits)(locale);
        default:
            throw RangeError('Invalid key: ' + key);
    }
}
